import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import MyForm from "../components/form"

const ContactPage = () => (
  <Layout>
    <SEO title="contact" />
    <h1>Contact me</h1>
    <MyForm />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ContactPage